<template>
  <div>
    <b-tabs
      v-model="tabActive"
      lazy
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-2"
      @activate-tab="onActiveTab"
    >
      <b-tab v-for="tab in tabs" :key="tab.key" :title="tab.title" lazy>
        <component :is="tab.component" :ref-table="tab.ref" :url="tab.url" :key-tab="tab.key" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabActive: 0,
      loading: false,
      tabs: [
        {
          key: 'all_refund',
          ref: 'ref_all_refund',
          title: 'ทั้งหมด',
          url: '/api/admin/userTransactionData?filter=creditRefundAll',
          component: () => import('./components/TabDynamicRefund.vue'),
        },
        {
          key: 'admin_refund',
          ref: 'ref_admin_refund',
          title: 'เฉพาะของแอดมินบัญชีนี้',
          url: '/api/admin/userTransactionData?filter=creditRefund',
          component: () => import('./components/TabDynamicRefund.vue'),
        },
      ],
    }
  },
  async created() {
    const { query } = this.$route
    if (query.tab) {
      const idx = this.tabs.findIndex(v => v.key === query.tab)
      if (idx >= 0) {
        this.tabActive = idx
      } else {
        this.$router.replace({ ...this.$route, query: { tab: this.tabs[0].key } })
      }
    }
  },
  methods: {
    onActiveTab(newTabIndex) {
      const tab = this.tabs[newTabIndex].key

      this.$router.replace({ ...this.$route, query: { tab } })
    },
  },
}
</script>

<style lang="scss" scoped></style>
